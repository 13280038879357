import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles ({
    popperContentThankYou: {
        padding: '1rem',
        textAlign: 'center',
        '@media (max-width: 960px)': {
            padding: '1rem 0.5rem',
        },
        '& > h3': {
            color: '#0a66c2',
            marginTop: 0,
        },
    },
});

const SubmitSuccess: React.FC<{ message: string }> = ({message}) => {
	const classes = useStyles();
	return (
	<>
	    <div className={classes.popperContentThankYou}>
	        <img
	            src='https://res.cloudinary.com/agiliti/image/upload/v1640248791/thank_you_icon.gif'
	            alt='Thank You'
	            width='152px'
	            height='128px'
	            title='Thank You, submitted successfully!'
	        />
	        <h3>{message}</h3>
	    </div>
	</>
	);
};

export default SubmitSuccess;